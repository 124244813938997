import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'

import SEO from '../components/seo'
import Layout from '../containers/layout'
import HeroSkinny from '../components/hero/HeroSkinny'
import {mapEdgesToNodes} from '../lib/helpers'
import Obfuscate from 'react-obfuscate'

export const query = graphql`
  query ImpressumPageQuery {

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title {
          _key
          _type
          en
          de
        }
      description
      keywords
      callToActionActive
      callToActionButtonText
      callToActionText
      mainImage {
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      seperatorImage {
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    menu:  sanityMenu(_id: {regex: "/(drafts.|)menu/"}) {
      id
      menuitems {
        _key
        _type
        name {
          _key
          _type
          de
          en
        }
        url
        menutype
      }
      menuName
    }

    impressum: sanityImpressum(_id: { regex: "/(drafts.|)impressum/" }) {
      id
      contactName
      addressZip
      addressStreet
      addressCity
      email
      tel
      programmingCredit
      programmingLink
      logoCredit
      legalNotice {
        _key
        _type
        de
        en
      }
      _rawBody
      title {
        _key
        _type
        de
        en
      }
    }

    testimonials: sanitySampleProject {
    id
    testimonialCards {
      _key
      _type
      title
      mainImage {
        _key
        _type
        caption
        alt
        copyright {
          _key
          _type
          photographer {
            _key
            _type
            name
            slug {
              _key
              _type
              current
            }
          }
        }
      }
    }
    }

    photographers: allSanityPhotographer {
      edges {
        node {
          name
          id
        }
      }
    }

    socialMedia: sanitySocialMedia {
      id
      socialMediaItems {
        _key
        _type
        url
        name
      }
    }

  }
`

const ImpressumPage = props => {
  const {data, errors, pageContext} = props

  const site = (data || {}).site
  const menu = (data || {}).menu
  const impressum = (data || {}).impressum
  const socialMedia = (data || {}).socialMedia

  const photographers =
    data &&
    data.photographers &&
    mapEdgesToNodes(data.photographers)

  const photographersList = photographers.map((photographer, index) => {
    return <li key={index}>{photographer.name}</li>
  })

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  if (errors) {
    return (
      <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage >
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage >
      <SEO title='Impressum' />
      <HeroSkinny fluid={site.mainImage.asset.fluid} />
      <Container>
        <section className='section--impressum' id='impressum'>

          <div className='impressum--wrapper'>
            {pageContext.intl.language === 'de'
              ? <div>
                <h1>{impressum.title.de}</h1>
                <h3>Kontakt</h3>
                <ul>
                  <li key='1'>{impressum.contactName}</li>
                  <li key='2'>{impressum.addressStreet}</li>
                  <li key='3'>{impressum.addressZip} {impressum.addressCity}</li>
                  <li key='4'>Email: <Obfuscate email={impressum.email} /> </li>
                  <li key='5'>Tel: <Obfuscate tel={impressum.tel} /></li>

                </ul>
                <h3>Photo Copyright</h3>
                <ul>
                  {photographersList}
                </ul>

                <h3>Webdesign & Programming</h3>
                <ul>
                  <li><a href={impressum.programmingLink} target='_blank' referrerPolicy='none' >{impressum.programmingCredit}</a></li>

                </ul>

                <h3>Logo Design</h3>
                <ul>
                  <li>{impressum.logoCredit}</li>

                </ul>
              </div>
              : <div>
                <h1>{impressum.title.en}</h1>
              </div>
            }
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default ImpressumPage
